<template>
  <b-modal
    id="modal-keuangan"
    body-class="p-2"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
    @hidden="resetModal()"
  >
    <b-overlay
      variant="light"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="lg"
    >
      <div class="d-flex w-100 justify-content-end">
        <a
          href="#"
          @click="currentStep === 4 ? currentStep-- : closeModal()"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
            height="18"
            width="18"
            alt="close"
            class="float-right"
          >
        </a>
      </div>

      <!-- error == penarikan saldo gagal,masih memiliki antrian -->
      <div
        v-if="currentStep === 1 && rekenings.length > 0"
        class="text-center"
      >
        <b-row class="justify-content-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/fail.svg"
            alt="fail"
            class="text-center alert"
          >
        </b-row>
        <b-row class="gap-15 justify-content-center">
          <p class="mt-2 h-text-md text-center text-dark font-bold text-10">
            Penarikan Saldo Gagal
          </p>
          <p class="text-dark">
            Maaf kamu tidak bisa melakukan penarikan saldo dkarenakan kamu masih memiliki antrian penarikan yang belum disetujui
          </p>
          <BButton
            variant="primary"
            class="mb-2"
            @click="closeModal()"
          >
            Lihat Riwayat Penarikan
          </BButton>
        </b-row>
      </div>

      <!-- INPUT WITHDRAWAL AMOUNT -->
      <!-- form -->
      <div
        v-if="currentStep === 2 && rekenings.length > 0"
        class="d-flex flex-column pad-x-10"
      >
        <div class="font-bold text-dark text-10 text-center">
          Penarikan Saldo
        </div>
        <validation-observer v-slot="{ invalid }">
          <form class="d-flex flex-column gap-15">
            <div class="d-flex flex-column">
              <label
                for="nominal-wd"
                class="h-text-dark font-bold text-8"
              >Nominal</label>
            </div>
            <div class="align-items-center">
              <validation-provider
                #default="{ errors }"
                name="Nominal"
                :rules="'required'"
              >
                <b-form-group
                  invalid-feedback="Nominal is required"
                >
                  <b-form-input
                    id="nominal-input"
                    v-model="nominal"
                    type="tel"
                    data-type="currency"
                    required
                    :placeholder="`minimal Rp10.000,-, maksimal Rp${formatPrice(saldo)},-`"
                    @keyup="formatCurrency(false, 'nominal-input')"
                    @keydown="evt => ['e', 'E', '+', '-', ',', '.'].includes(evt.key) && evt.preventDefault()"
                    @input="checkWithdraw"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                <b-row
                  v-if="minWithdraw"
                  class="mt-50"
                >
                  <b-col cols="12">
                    <small class="text-primary">*Minimal penarikan Rp10.000</small>
                  </b-col>
                </b-row>
                <b-row
                  v-if="isMaxWithdraw"
                  class="mt-50"
                >
                  <b-col cols="12">
                    <small class="text-primary">*Saldo kamu tidak mencukupi</small>
                  </b-col>
                </b-row>
              </validation-provider>
            </div>
            <div class="d-flex flex-column">
              <label class="h-text-dark font-bold text-8">Rekening</label>
            </div>
            <v-select
              v-model="rekeningSelected"
              :options="rekenings"
              placeholder="Pilih rekening anda"
            >
              <template
                #selected-option-container="{ option }"
              >
                <div class="vs__selected">
                  {{ option.bank + ' - ' + option.nama }}
                </div>
              </template>
            </v-select>
            <div class="align-self-start mt-[-5px]">
              <span
                class="text-black"
              >Pastikan Rekening yang kamu pilih sudah benar</span>
            </div>
            <div class="pb-1 justify-content-center d-flex mt-2">
              <button
                class="button-secondary w-100 py-[11px] mr-1"
                @click="$bvModal.hide('modal-keuangan')"
              >
                Batal
              </button>
              <button
                class="button-primary w-100 py-[12px]"
                type="submit"
                :disabled="invalid || minWithdraw || isMaxWithdraw || validateWithdraw() || disabledAjukan"
                @click="currentStep++"
              >
                Ajukan Penarikan
              </button>
            </div>
          </form>
        </validation-observer>
      </div>

      <!-- warning == belum menambahkan rekening -->
      <div
        v-if="rekenings.length === 0"
        class="modal-add-pickup-popup-success"
      >
        <b-row class="justify-content-center mb-1 pt-1">
          <img src="@/assets/images/icons/warning.svg">
        </b-row>
        <b-row class="text-center px-4 mb-1">
          <p>
            Maaf, kamu belum menambahkan rekening untuk penarikan saldo.
          </p>
        </b-row>
        <b-row class="justify-content-center pb-1">
          <BButton
            class="org-button text-center"
            variant="primary"
            @click="$router.push('/rekening-bank')"
          >
            Tambah Rekening
          </BButton>
        </b-row>
      </div>

      <!-- PIN CONFORMATION -->
      <div v-show="currentStep === 3">
        <BCol class="d-flex justify-content-center mt-2">
          <h4 class="text-black font-semibold">
            Konfirmasi PIN
          </h4>
        </BCol>

        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :loading="false"
            class="input"
            :style="
              !isVisibility
                ? '-webkit-text-security: disc'
                : '-webkit-text-security: none'"
            @complete="onCompletePin"
          />
        </BCol>

        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="isVisibility = !isVisibility"
          >
            <span>
              Tampilkan / Sembunyikan
            </span>
          </BButton>
        </BCol>

        <BCol class="d-flex justify-content-center mt-1 pb-2">
          <BButton
            variant="primary"
            @click="confirmPin()"
          >
            Konfirmasi
          </BButton>
        </BCol>
      </div>

      <!-- error == penarikan saldo gagal, pin salah -->
      <div
        v-show="currentStep === 4"
        class="text-center"
      >
        <b-row class="justify-content-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/fail.svg"
            alt="fail"
            class="text-center alert"
          >
        </b-row>
        <b-row class="gap-15 justify-content-center">
          <p class="mt-2 h-text-md text-center text-dark font-bold text-10">
            Penarikan Saldo Gagal
          </p>
          <p class="text-dark w-100">
            Maaf PIN yang anda masukkan salah
          </p>
          <BButton
            variant="primary"
            class="mb-2"
            @click="currentStep--"
          >
            Oke
          </BButton>
        </b-row>
      </div>

      <!-- success == withdrawal success -->
      <div
        v-if="currentStep === 5"
        class="text-center"
      >
        <b-row class="justify-content-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/success.svg"
            alt="success"
            class="text-center alert"
          >
        </b-row>
        <b-row class="gap-15 justify-content-center">
          <p class="mt-2 h-text-md text-center text-dark font-bold text-10">
            Penarikan Saldo Berhasil
          </p>
          <p class="text-dark px-3">
            Saldo sebesar {{ nominal }} akan segera dikirim ke rekening atas
            nama {{ rekeningSelected.nama }} - {{ rekeningSelected.bank }} dalam 1x24 jam
          </p>
        </b-row>
      </div>

      <!-- error == penarikan saldo gagal,masih memiliki antrian -->
      <div
        v-if="currentStep === 6"
        class="text-center"
      >
        <b-row class="justify-content-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/fail.svg"
            alt="fail"
            class="text-center alert"
          >
        </b-row>
        <b-row class="gap-15 justify-content-center">
          <p class="mt-2 h-text-md text-center text-dark font-bold text-10">
            Penarikan Saldo Gagal
          </p>
          <p class="text-dark">
            Maaf kamu tidak bisa melakukan penarikan saldo dkarenakan kamu masih memiliki antrian penarikan yang belum disetujui
          </p>
          <BButton
            variant="primary"
            class="mb-2"
            @click="closeModal()"
          >
            Lihat Riwayat Penarikan
          </BButton>
        </b-row>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import CodeInput from 'vue-verification-code-input'
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import { BIconChatLeftTextFill } from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    CodeInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isVisibility: false,
      required,
      minValue,
      minWithdraw: false,
      isMaxWithdraw: false,
      disabledAjukan: false,
    }
  },
  computed: {
    ...mapFields('saldo', [
      'pin',
      'nominal',
      'rekeningSelected',
      'currentStep',
    ]),
    ...mapState('saldo', [
      'saldo',
      'saldoPending',
      'table',
      'riwayatPenarikans',
    ]),
    ...mapGetters('saldo', ['rekenings', 'rekening', 'rekTujuanOptions']),
  },
  mounted() {
    const [firstRekening] = this.rekenings

    this.nominal = ''
  },
  methods: {
    formatNumber(n) {
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatCurrency(blur, el) {
      const input = document.getElementById(el)
      let inputVal = input.value

      if (inputVal === '' || inputVal === 'Rp' || inputVal === 'Rp ') {
        return
      }

      const originalLen = inputVal.length
      let caretPos = input.selectionStart

      inputVal = this.formatNumber(inputVal)
      inputVal = 'Rp ' + inputVal // eslint-disable-line

      input.value = inputVal

      const updatedLen = inputVal.length
      caretPos = updatedLen - originalLen + caretPos
      input.setSelectionRange(caretPos, caretPos)
    },
    formatRibuan(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatRupiah(x) {
      return `Rp ${this.formatRibuan(x)}`
    },
    closeModal() {
      this.$bvModal.hide('modalTopUp')
      this.$bvModal.hide('modal-keuangan')
    },
    confirmPin() {
      this.$store.dispatch('saldo/checkPin')
        .then(res => {
          /* eslint-disable camelcase */
          const { is_match } = res.data.data

          if (is_match) this.sendRequestWithdrawal()
          else this.currentStep += 1
        })
        .catch(err => {
          this.alertError()
        })
    },
    sendRequestWithdrawal() {
      this.$store.dispatch('saldo/withdrawalRequest')
        .then(res => {
          this.currentStep = 5
        })
        .catch(err => {
          if (err.response.data.message
          === 'Can`t Create Withdrawal Request, You already Have an open Withdrawal Request!'
          ) this.currentStep = 6

          else this.alertError()
        })
    },
    alertError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text:
            'Unable to get the data. Please try again later or contact support.',
          variant: 'danger',
        },
      })
    },
    formatPrice(value) {
      const val = value
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    onCompletePin(pin) {
      this.pin = pin
    },
    validateWithdraw() {
      return !this.rekeningSelected || this.rekenings.length === 0
    },
    checkWithdraw() {
      const nominalValue = Number(this.nominal.replace(/[^0-9,-]+/g, ''))

      if (this.nominal === 'Rp' || this.nominal === 'Rp ' || this.nominal === '' || this.nominal === 'Rp 0' || this.nominal === 'R') {
        this.disabledAjukan = true
      } else {
        this.disabledAjukan = false
      }

      if (this.nominal && this.nominal !== 'Rp' && this.nominal !== 'Rp ') {
        if (nominalValue < 10000) {
          this.minWithdraw = true
          this.isMaxWithdraw = false
        } else if (nominalValue > this.saldo) {
          this.minWithdraw = false
          this.isMaxWithdraw = true
        } else {
          this.minWithdraw = false
          this.isMaxWithdraw = false
        }
      } else {
        this.minWithdraw = false
        this.isMaxWithdraw = false
      }
    },
    resetModal() {
      this.currentStep = 1
      this.nominal = ''
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__dropdown-menu {
  max-height: 180px;
}
.vs--open .vs__dropdown-toggle {
  border-color: $input-focus-border-color;
  border-bottom-color: $input-focus-border-color;
  border-bottom-left-radius: $vs-border-radius;
  border-bottom-right-radius: $vs-border-radius;
  box-shadow: $input-focus-box-shadow;
}
</style>
<style lang="scss" scoped>
$primary: #F95031;
$orange: #ff6a3a;
$grayscale: #828282;
$black--text: #222222;

.text {
  @for $i from 1 through 20 {
    &-#{$i} {
      font-size: #{$i * 2}px;
    }
  }
}
.gap {
  @for $i from 1 through 20 {
    &-#{$i} {
      gap: #{$i}px;
    }
  }
}
.pad {
  @for $i from 1 through 20 {
    &-#{$i} {
      padding: #{$i * 2}px;
    }
  }
}
.pad-x {
  @for $i from 1 through 20 {
    &-#{$i} {
      padding-left: #{$i * 2}px;
      padding-right: #{$i * 2}px;
    }
  }
}
.text-dark {
  color: #222222 !important;
}
img.alert {
  height: 120px;
}
.button-secondary {
  background-color: #FFF;
  border: 1px solid $primary;
  color: $primary;
  border-radius: 7px;
  font-weight: 500;
}

.button-primary {
  background-color: $primary;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 10px;
  font-weight: 500;
}

.button-primary:disabled {
  background-color: #C2C2C2;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
}
</style>
